html {
  font-size: 15px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a.textlink {
  color: red;
  text-decoration: none;
  color: rgba(78,84,107,0.6);
}

a.textlink:hover {
  color: rgba(78,84,107,1);
  transition-duration: 0.3s;
}
